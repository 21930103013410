
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import styles from "../../styles/Form.module.css";
import { WishlistContext } from '../../contexts/WishlistContext';
import { FaFacebookF, FaGoogle, FaWindowRestore } from "react-icons/fa";
import { HiAtSymbol, HiLockClosed,HiLockOpen } from "react-icons/hi";
import { ThemeContext } from "../../contexts/ThemeContext";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
import { useAxios } from "../../utils/axios";
import ReactLoading from "react-loading";
import { BUILDER_URL, setImageSrc } from '../../utils/config'; 
import LoadingAnimation from '../../components/custom/LoadingAnimation';
const BASE_URL = BUILDER_URL;
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import * as Sentry from "@sentry/react";
import { Spinner } from "@material-tailwind/react";
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';
import getRedisClient from '../../utils/redis';
import getRedisClientLocal from '../../utils/localredis';
import useTracking from '../../components/custom/useTracking';
import {  deleteCookie } from '../../utils/cookie';
import CustomPopup from '../../components/CustomPopup';
import { useGoogleAuth } from '../../components/custom/useGoogleAuth';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Validation schema for sign in form using Yup
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/, "Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    // .min(8, "Password should be at-least 8 characters."),
});

/**
 * SignIn component for handling user login.
 *
 * This component provides a form for user sign-in, including validation and submission.
 * It fetches additional data like website name and description for display purposes.
 */
const SignIn = ({ dataValue, onRegisterClick,serverReferrer=""  }) => {
  // Form setup using react-hook-form
  console.log("Server Url",serverReferrer)
 
  
  
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  
  // State management
  const [show, setShow] = useState(false);
  const trackPage = useTracking('Sign In Page');
  const theme = useContext(ThemeContext);
  const [googleAuthStatus,setGoogleAuthStatus]=useState(null);
  const {fetchWishlist } = useContext(WishlistContext);
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const router = useRouter();
  const { login, loading, PhoneNumberModal } = useGoogleAuth();
  const { setUserId, setToken, setUserDetails } = useContext(UserContext);
  
  const [websiteName, setWebsiteName] = useState("");
  const [websiteDescription, setWebsiteDescription] = useState("");
  const [designImageUrl, setDesignImageUrl] = useState("");
  const [loader, setLoader] = useState(true);
  const { jboId } = useJobId();
   // State for custom popup
   const [isPopupOpen, setPopupOpen] = useState(false);
   const [popupConfig, setPopupConfig] = useState({});
 
   const openPopup = (config) => {
     setPopupConfig(config);
     setPopupOpen(true);
   };
 
   const closePopup = () => {
     setPopupOpen(false);
   };
   
   
  // Function to handle form submission
  const onSubmit = async (data) => {
    setLoader(false)
    const { email, password } = data;
    const payload = {
      email,
      password,
      remember: "on",
      // "g-recaptcha-response": recaptchaValue,
    };
    try {
      const response = await axiosInstance.post("user/user_login", payload);
      if (response.status === 200) {
        
        setTimeout(() => {
          setLoader(true)
        }, 2000);
        
      }
      
      try {
        const userDetailsFromResponse = response.data.user_details[0];

        // Assuming user_type is a separate field in your response, add it to userDetails
        const updatedUserDetails = {
          ...userDetailsFromResponse,
          user_type: response.data.user_type, // Add user_type here
        };

        setUserId(response.data.user_details[0].user_id);
        setToken(response.data.token);
        setUserDetails(updatedUserDetails); // Update the context with the new userDetails including user_type
        localStorage.setItem("userId", response.data.user_details[0].user_id);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userDetails", JSON.stringify(updatedUserDetails));
        localStorage.setItem("privilegeStatus", response.data.privilege_card||0); // set privilege status
      } catch (error) {
        const jboID = jboId;
        const endpoint = "user/user_login";
        const payLoad = {
          email,
          password,
          remember: "on",
        };
        const responseData = error?.response?.data ? error.response.data : '';
        const errorMsg = error;
        errorLogApi({
          job_id: jboID,
          endpoint: endpoint,
          payload: payLoad,
          responseData: responseData,
          errorMsg: errorMsg
        });
      }

      
      if (response.data.status === "0") {
        setLoader(true)
        openPopup({
          type: 'error',
          title: 'Error!',
          subText: response.data.message,
          onConfirm: closePopup,
          onClose: closePopup,
          autoClose: 2,
          confirmLabel: 'Try again',
          showConfirmButton: false,
          showCancelButton: false,
        });
        
      } else {
        window.location.href=serverReferrer
         // redirects the initial referrer URL stored in cookie
        //  window.location.href = getCookie('initialReferrer');
         deleteCookie('initialReferrer');
       
      }

      reset();
     
    } catch (error) {
      const jboID = jboId;
      const endpoint = "user/user_login";
      const payLoad = '';
      const responseData = '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg
      });

      openPopup({
        type: 'error',
        title: 'Error!',
        subText: error.toString(),
        onConfirm: closePopup,
        onClose: closePopup,
        confirmLabel: 'Try again',
        showConfirmButton: false,
        autoClose: 2,
        showCancelButton: false,
      });
    }
  };
  // Fetching website data for display
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth-page`, {
        headers: { Authorization: `Bearer ${theme.token}` },
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const data = response.data[0]; // Assuming you need the first item in the array
          setWebsiteName(data.website_name);
          setWebsiteDescription(data.website_description);
          setDesignImageUrl(data.design_image_url);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
      });


    
      axiosInstance.post(`login/viewGoogleAuthuser`)
    .then(response => {
      if (response.data.status == 1) {
        const {  enb } = response.data.data;
        setGoogleAuthStatus(enb);
       
      } else {
        console.error("Failed to fetch Google Auth data");
      }
    })
    .catch(error => {
      console.log("Error fetching Google Auth data:", error);
    });
      trackPage();
  }, [theme]);
  // Function to truncate HTML content
  const truncateHTML = (html, wordLimit) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    let textContent = div.textContent || div.innerText || "";

    let words = textContent.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }

    return html;
  };
  console.log("googleAuthStatusNew",googleAuthStatus);
  // JSX for the SignIn component
  return (
    <>
      {/* <Layout hideLayout={true}> */}
      {isLoading ? (
        <div className="w-full h-screen">
        <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className="flex justify-center mt-10">
  
</div>
        <div className="flex flex-col md:flex-row justify-center items-center min-h-screen">
        
          <div className="max-w-[70rem] w-full m-auto md:m-0">
            <div className="items-center lg:py-10 lg:px-4">
              <div className="m-auto bg-slate-50 rounded-md grid lg:grid-cols-2">
                
              <div className="hidden lg:block h-full w-full">
                    <img
                      src={setImageSrc(designImageUrl, "")}
                      alt="Register"
                      layout="fill"
                      objectFit="cover"
                      className="sticky top-0 h-full rounded-md"
                    />
                  </div>
        <div className="right flex flex-col justify-evenly overflow-y-auto custom-scrollbar max-h-screen">
        <Link href={serverReferrer} className="absolute top-0 left-0 mt-4 ml-4 inline-block bg-primary text-white font-bold py-2 px-4 rounded-lg shadow hover:shadow-lg transition duration-300 ease-in-out">
    <FaArrowLeft className="inline-block mr-2" />
  </Link>

  {/* Home Button */}
  <Link href="/" className="absolute top-0 right-0 mt-4  inline-block bg-primary text-white font-bold py-2 px-4 rounded-lg shadow hover:shadow-lg transition duration-300 ease-in-out">
    <FaHome className="inline-block" size={20} /> 
  </Link>
                  <div className="text-center">
                    <section className="w-full mx-auto flex p-5 flex-col gap-10">
                    <div>
      <h1 className="text-primary text-4xl font-bold py-4">
        {websiteName}
      </h1>
      <div className="title overflow-auto py-4">
        <p
          name={websiteDescription}
          className="w-3/4 mx-auto"
          dangerouslySetInnerHTML={{
            __html: websiteDescription,
          }}
        />
      </div>
    </div>
    {googleAuthStatus == 1 &&  <div className="flex flex-col items-center">
      <button
        onClick={login}
        className="flex items-center justify-center w-full max-w-xs px-6 py-3 border-2 border-primary rounded-full shadow-md hover:shadow-lg transition-shadow duration-200 bg-white"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/2702/2702602.png"
          alt="Google"
          className="w-6 h-6 mr-3"
        />
        <span className="text-primary font-semibold">SignIn with Google</span>
      </button>
    
      <div className="flex items-center mt-6 w-full max-w-xs">
        <div className="border-t border-gray-500 flex-grow"></div>
        <span className="text-gray-500 mx-3">Or SignIn with Account</span>
        <div className="border-t border-gray-500 flex-grow"></div>
      </div>
    </div>
 }
    <PhoneNumberModal />

                      {/* form */}
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col gap-5"
                      >
                        <div className={styles.input_group}>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className={styles.input_text}
                            {...register("email")}
                          />
                          <span className="icon flex items-center px-4">
                            <HiAtSymbol size={25} />
                          </span>
                        </div>
                        {errors.email && (
                          <span className="error text-error">
                            {errors.email.message}
                          </span>
                        )}

                        <div className={styles.input_group}>
                          <input
                            type={show ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            className={styles.input_text}
                            {...register("password")}
                          />
                          <span
                            className="icon flex items-center px-4"
                            onClick={() => setShow(!show)}
                          >
                            {show ? <HiLockOpen size={25} /> : <HiLockClosed size={25} />}
                          </span>
                        </div>

                        {errors.password && (
                          <span className="error text-error">
                            {errors.password.message}
                          </span>
                        )}
                        {/* Forgot Password */}

                        <div className="text-right">
                          <Link href="/auth/ForgotPassword">
                            <span className="text-lg text-primary ">
                              Forgot Password?
                            </span>
                          </Link>
                        </div>

                        {/* <div className="flex justify-center items-center">
                      {isValid && (
                          <div className="flex justify-center items-center">
                            <ReCAPTCHA
                              sitekey="6LdP5OYmAAAAAFVGaeRtVPKsTXURgAOcpyeuPjXF"
                              onChange={handleRecaptcha}
                            />
                          </div>
                        )}
                      </div> */}
                        <div className="flex items-center space-x-3 mb-2 mt-2">
                          <input
                            id="subscribe"
                            type="checkbox"
                            name="subscribe"
                            defaultChecked={true}
                            className="rounded text-primary focus:border-transparent ring-0 mt-1"
                            {...register("subscribe")}
                          />
                          <label
                            htmlFor="subscribe"
                            className="mt-2 text-gray-500"
                          >
                            Remember me
                          </label>
                        </div>
                        {/* login button */}
                        <div className="input-button">
                          <button
                            type="submit"
                            className={`font-bold ${styles.button} ${
                              !isValid ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                            disabled={!isValid}
                          >
                            <div className="flex justify-center gap-2 items-center">
                              {loader ?  'Login' : <><Spinner color="primary" className="h-6 w-6"/> <span> Login </span></>}
                            </div>
                          </button>
                        </div>
                        {/* <div className="mt-6 flex justify-center relative ">
                          <div className="absolute left-0 top-3 w-full border-b-2 border-gray-200"></div>
                          <div className='text-gray-600 uppercase px-3 bg-white relative z-10'>Or login with</div>
                        </div>
                      <div className='mt-4 flex gap-4 justify-center'>
                          <Link href="#">
                            <div className='p-2 bg-blue-700 rounded-full text-white hover:bg-blue-800 transform hover:scale-110 transition-transform'>
                              <FaFacebookF />
                            </div>
                          </Link>
                          <Link href="#">
                            <div className='p-2 bg-red-600 rounded-full text-white hover:bg-red-700 transform hover:scale-110 transition-transform'>
                              <FaGoogle />
                            </div>
                          </Link>
                        </div> */}
                      </form>
                    
                      {/* bottom */}
                      {dataValue !== "1" ? (
                        <p className="text-center text-gray-400">
                          don't have an account yet?
                          <Link href="/auth/Register">
                            <span className="text-primary"> Sign Up</span>
                          </Link>
                        </p>
                      ) : (
                        <p className="text-center text-gray-400">
                          don't have an account yet?
                          <a
                            onClick={() => onRegisterClick()}
                            className="text-primary cursor-pointer"
                          >
                            {" "}
                            Sign Up
                          </a>
                        </p>
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
   <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={popupConfig.onConfirm}
        onClose={popupConfig.onClose}
        autoClose={popupConfig.autoClose}
        confirmLabel={popupConfig.confirmLabel}
        cancelLabel={popupConfig.cancelLabel}
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        showCloseIcon={popupConfig.showCloseIcon}
      />
      {/* </Layout> */}
    </>
  );
};

function parseCookies(cookieHeader) {
  const cookies = {};
  if (!cookieHeader) return cookies;
  const pairs = cookieHeader.split(';');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].trim().split('=');
    cookies[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return cookies;
}

// To implement previous url from router

export async function getServerSideProps(context) {
  const { req, res } = context;

  // Helper function to extract the domain name
  function sanitizeHostname(host) {
    const hostname = new URL(`https://${host}`).hostname;
    return hostname;
  }

  const logError = (endpoint, error) => {
    const responseData = error.response?.data ?? '';
    errorLogApi({
      job_id: "",
      endpoint: endpoint,
      payload: '',
      responseData: responseData,
      errorMsg: error
    });
  };

  const hostname = req.headers.host === "localhost:3000" ? process.env.NEXT_PUBLIC_WEB_HOST : sanitizeHostname(req.headers.host);

  const redishostname = sanitizeHostname(req.headers.host);
  const redis = redishostname.startsWith('localhost') ? getRedisClientLocal() : getRedisClient();

  const previewUrl = req.headers.host === "localhost:3000"
    ? "https://preview.jpencil.in/?pid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjIzMywic2l9zdGF0dXMiOjAsImlhdCI6MTcxNDY0MzI4MX0.QXsuG6PL2rBZP92YZMTeMRnO3at8Sfq2gOf22rG6jeg"
    : req.url;

  const urlObj = new URL(previewUrl, `http://${hostname}`);
  const pid = urlObj.searchParams.get('pid');

  
  let parsedCookie = parseCookies(req.headers.cookie)
  // console.log(" parsed cookie : ", parsedCookie)

  let serverReferrer = parsedCookie.initialReferrer ?? '/';

  // console.log("________________")
  // console.log(" Server: " + serverReferrer)
  // console.log("________________")


  if (hostname.startsWith('preview.')) {
    const innerJson = JSON.stringify({
      domain: hostname,
      token: pid
    });

    const payload = {
      data: innerJson
    };

    try {
      const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return { props: { products: response.data, serverReferrer } };
    } catch (error) {
      logError('getJbo', error);
    }
  }

  try {
    const cachedProducts = await redis.get(hostname);
    console.log("cachedProducts siginin",cachedProducts);
    if (cachedProducts) {
      return { props: { products: JSON.parse(cachedProducts), serverReferrer } };
    }

    // If no cache, make an API call for no cached Products
    const innerJson = JSON.stringify({ domain: hostname });
    const payload = {
      data: JSON.stringify({ domain: hostname })
    };

    const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
      headers: { 'Content-Type': 'application/json' }
    });
console.log("redis data",response.data);
    if (response.data && Object.keys(response.data).length) {
      await redis.set(hostname, JSON.stringify(response.data));  // Cache the response
      return { props: { products: response.data, serverReferrer } };
    }

    // Handle the scenario where the API call does not return data
    logError('getJbo', error);
    return { props: { products: [], serverReferrer } };
  } catch (error) {
    logError('getJbo', error);

    return { props: { products: [], serverReferrer } };
  }
}
export default SignIn;