import { useRouter } from "next/router";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ThemeContext } from "../../contexts/ThemeContext";
import { RedisContext } from "../../contexts/RedisContext";
import "suneditor/dist/css/suneditor.min.css";
import { BUILDER_URL } from "../../utils/config";
import LoadingAnimation from "../../components/custom/LoadingAnimation";
const BASE_URL = BUILDER_URL;
import * as Sentry from "@sentry/react";
import getRedisClient from "../../utils/redis";
import getRedisClientLocal from "../../utils/localredis";
import useTracking from "../../components/custom/useTracking";

const CustomPage = () => {
  // footer query modifications-13-08-2024
  const router = useRouter();
  const { slug = [] } = router.query; // slug  from the URL query
 console.log("slug datas");
  const [data, setData] = useState(null);
  const { footerDatas } = useContext(RedisContext);
  const trackPage = useTracking(`Custom Page ${slug}`);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (slug && footerDatas) {
    // Ensure slug is a string
    const slugString = Array.isArray(slug) ? slug[0] : slug;

    // Resolve slug to custom_page_id
    const pageMapping = footerDatas?.footer_menu
      ?.flatMap((menu) => menu.custom_page || [])
      .find(
        (cp) =>
          cp.custom_page_title.toLowerCase().replace(/\s+/g, "-") ===
          slugString?.toLowerCase()
      );

    const customPageId = pageMapping?.custom_page_id;

      axios
        .get(`${BASE_URL}/custom-page?custom_page_id=${customPageId}`, {
          headers: { Authorization: `Bearer ${theme.token}` },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setData(response.data[0]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Sentry.captureException(error);
        });
    }
    trackPage();
  }, [slug, theme]);

  return (
    <>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="container w-full h-full mx-auto px-4 py-6 bg-white shadow-md rounded-md overflow-auto">
          <h1 class="text-primary mb-4 block text-2xl lg:text-3xl text-center capitalize font-semibold">
            {data?.custom_page_title}
          </h1>
          <div
            className="prose prose-sm sm:prose lg:prose-lg mx-auto sun-editor-editable"
            style={{ maxWidth: "100%" }}
            dangerouslySetInnerHTML={{ __html: data?.custom_page_editor }}
          ></div>
        </div>
      )}
    </>
  );
};

export async function getServerSideProps(context) {
  const { req } = context;

  function sanitizeHostname(host) {
    const hostname = new URL(`https://${host}`).hostname;
    return hostname;
  }

  const logError = (endpoint, error) => {
    const responseData = error.response?.data ?? "";
    errorLogApi({
      job_id: "",
      endpoint: endpoint,
      payload: "",
      responseData: responseData,
      errorMsg: error,
    });
  };

  const hostname =
    req.headers.host === "localhost:3000"
      ? process.env.NEXT_PUBLIC_WEB_HOST
      : sanitizeHostname(req.headers.host);
  const redishostname = sanitizeHostname(req.headers.host);
  const redis = redishostname.startsWith("localhost")
    ? getRedisClientLocal()
    : getRedisClient();

  const previewUrl =
    req.headers.host === "localhost:3000"
      ? "https://preview.jpencil.in/?pid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjIzMywic2l0ZV9zdGF0dXMiOjAsImlhdCI6MTcxNDY0MzI4MX0.QXsuG6PL2rBZP92YZMTeMRnO3at8Sfq2gOf22rG6jeg"
      : req.url;

  const urlObj = new URL(previewUrl, `http://${hostname}`);
  const pid = urlObj.searchParams.get("pid");

  if (hostname.startsWith("preview.")) {
    const innerJson = JSON.stringify({
      domain: hostname,
      token: pid,
    });

    const payload = {
      data: innerJson,
    };

    try {
      const response = await axios.post(
        `https://${hostname}/api/user/getJbo`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return { props: { products: response.data } };
    } catch (error) {
      logError("getJbo", error);
    }
  }

  try {
    const cachedProducts = await redis.get(hostname);

    if (cachedProducts) {
      return { props: { products: JSON.parse(cachedProducts) } };
    }

    const innerJson = JSON.stringify({ domain: hostname });
    const payload = {
      data: JSON.stringify({ domain: hostname }),
    };

    const response = await axios.post(
      `https://${hostname}/api/user/getJbo`,
      payload,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.data && Object.keys(response.data).length) {
      return { props: { products: response.data } };
    }

    logError("getJbo", error);
    return { props: { products: [] } };
  } catch (error) {
    logError("getJbo", error);
    return { props: { products: [] } };
  }
}

export default CustomPage;
