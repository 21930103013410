import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAxios } from "../../utils/axios";
import Link from "next/link";
import getRedisClient from '../../utils/redis';
import getRedisClientLocal from '../../utils/localredis';
import { BUILDER_URL, setImageSrc } from '../../utils/config';
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';
import useTracking from '../../components/custom/useTracking';
import Image from 'next/image';
import SEO from "../../components/custom/SEO";
import {NoDataFound} from '../../pages/404';
/**
* CategoryList component for displaying a list of products based on category.
*
* This component fetches product data based on the category from the URL slug
* and displays them. It also handles loading and no data states.
*/
const CategoryList = () => {
 // State Management
   const router = useRouter();
   const trackPage = useTracking('Category List Page');  
   const [products, setProducts] = useState([]);
   const [seoTitle, setSeoTitle] = useState("");
   const [seoDescription, setSeoDescription] = useState("");
   const [seoKeywords, setSeoKeywords] = useState("");
   const [isLoading, setIsLoading] = useState(true);
   const { jboId } = useJobId();
    const axios = useAxios();
   
     // Fetch products based on the URL slug
     const { slug = [] } = router.query;
   useEffect(() => {
     if (router.isReady) {
  
  
       if (slug && slug.length > 0) {
         const urlPath = `/${slug.join('/')}`;
         const payload = { url: urlPath };
  
         const fetchProducts = async () => {
           setIsLoading(true);
           try {
             const response = await axios.post('product/productListNew', payload);
            
            setSeoTitle(response?.data?.page_property?.seo_title);
       
            setSeoDescription(response?.data?.page_property.seo_description);
           setSeoKeywords(response?.data?.page_property?.seo_keyword);
               setProducts(response?.data?.thumbnail);
             // }
           } catch (error) {
             const jboID = jboId;
             const endpoint = 'product/productListNew';
             const payLoad = { url: urlPath };
             const responseData = error?.response?.data ? error.response.data : '';
             const errorMsg = error;
             errorLogApi({
               job_id: jboID,
               endpoint: endpoint,
               payload: payLoad,
               responseData: responseData,
               errorMsg: errorMsg
             });
             // setNoData(false);
           }
           setIsLoading(false);
         };
  
         fetchProducts();
         trackPage();
       }
     }
   }, [router.isReady, router.query]);
  
// Placeholder for loading state
     const ShimmerPlaceholder = () => {
       return (
         <div className="xl:container px-4 mx-auto p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
           {[...Array(10)].map((_, index) => (
             <div key={index} className="flex flex-col overflow-hidden shadow-lg rounded-lg animate-pulse">
               <div className="h-72 w-full bg-gray-300 shimmer"></div>
               <div className="flex-1 p-4 space-y-3">
                 <div className="h-6 bg-gray-300 shimmer rounded"></div>
                 <div className="h-6 bg-gray-300 shimmer rounded w-1/2"></div>
               </div>
             </div>
           ))}
         </div>
       );
     };
      //  Handling loading state
   if (isLoading) {
     return <><ShimmerPlaceholder /></>;
   }
   // Handling no data state
   if (!products || products?.length === 0) {
     return (
      <NoDataFound/>
     );
   }
  const formatSlug = (str) => {
  return str
    .replace(/[_-]/g, ' ') // Replace underscores and hyphens with spaces
    .split(' ') // Split by space
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(' '); // Join words with space
};
   // JSX for the CategoryList component
 return (
   <>
    <SEO
        title={seoTitle}
        description={seoDescription}
        keyword={seoKeywords}
      />
    {/* Start Breadcrumbs */}
    <div className='xl:container px-4 py-4 flex justify-between '>
                           <div className='flex gap-3 items-center'>
                                     <Link href="/" className='text-primary text-base'>
                                             <i className='fas fa-home'></i>
                                     </Link>
                                   <div className='flex gap-3 items-center mt-1'>
                                   <span className='text-sm text-gray-500'>
                                             <i className='fas fa-chevron-right '></i>
                                     </span>
                                 <h1 className="text-gray-500 font-medium uppercase">
  {formatSlug(slug[1])}
</h1>
                                   </div>
                           </div>
               </div>
           {/* End Breadcrumbs */}
   <div className="xl:container px-4 mx-auto p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
   {products?.map((product, index) => (
     <div key={index} className="flex flex-col overflow-hidden shadow-lg rounded-lg cursor-pointer transform transition duration-500 hover:scale-105">
       <Link href={`${product.url}`} className="flex-shrink-0 relative h-72 w-full"> {/* Add relative class and define height */}
 <Image
   onLoad={(e) => e.target.classList.remove("shimmer")}
   alt={product.name}
   src={setImageSrc(product.thumbnail_image, '')}
   layout="fill" // Using fill layout
   objectFit="cover" // To maintain aspect ratio
   className="shimmer"
 />
</Link>
       <div className="flex-1 bg-white p-4 flex flex-col justify-between">
         <h2 className="text-xl font-semibold text-gray-800 mb-3">
           {product.name}
         </h2>
         <Link href={`${product.url}`} className="text-primary hover:underline text-sm">
           View Product
         </Link>
       </div>
     </div>
   ))}
 </div>
   </>
 )
}
function sanitizeHostname(host) {
 const hostname = new URL(`https://${host}`).hostname;
 return hostname;
}


const logError = (endpoint, error) => {
 const responseData = error.response?.data ?? '';
 errorLogApi({
   job_id: "",
   endpoint: endpoint,
   payload: '',
   responseData: responseData,
   errorMsg: error
 });
};






function parseCookies(cookieHeader) {
 const cookies = {};
 if (!cookieHeader) return cookies;
 const pairs = cookieHeader.split(';');
 for (let i = 0; i < pairs.length; i++) {
   const pair = pairs[i].trim().split('=');
   cookies[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
 }
 return cookies;
}




export async function getServerSideProps(context) {
 const { req, res } = context;


  // Parse cookies
  const cookies = parseCookies(req.headers.cookie);
  const userDetails = cookies.userDetails ? JSON.parse(cookies.userDetails) : null;
  const siteData = cookies.productsData   ? JSON.parse(cookies.productsData) :null;
  // Redirect to auth page if userDetails is null
 




 const hostname = req.headers.host === "localhost:3000" ? process.env.NEXT_PUBLIC_WEB_HOST : sanitizeHostname(req.headers.host);
 const redishostname = sanitizeHostname(req.headers.host);
 const redis = redishostname.startsWith('localhost') ? getRedisClientLocal() : getRedisClient();


 const previewUrl = req.headers.host === "localhost:3000"
   ? "https://preview.jpencil.in/?pid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjIzMywic2l0ZV9zdGF0dXMiOjAsImlhdCI6MTcxNDY0MzI4MX0.QXsuG6PL2rBZP92YZMTeMRnO3at8Sfq2gOf22rG6jeg"
   : req.url;


 const urlObj = new URL(previewUrl, `http://${hostname}`);
 const pid = urlObj.searchParams.get('pid');


 if (hostname.startsWith('preview.')) {
   const innerJson = JSON.stringify({
     domain: hostname,
     token: pid
   });


   const payload = {
     data: innerJson
   };


   try {
     const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
       headers: {
         'Content-Type': 'application/json'
       }
     });


     return { props: { products: response.data } };
   } catch (error) {
     logError('getJbo', error);
   }
 }


 try {
   const cachedProducts = await redis.get(hostname);


   if (cachedProducts) {
     return { props: { products: JSON.parse(cachedProducts) } };
   }


   const innerJson = JSON.stringify({ domain: hostname });
   const payload = {
     data: JSON.stringify({ domain: hostname })
   };


   const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
     headers: { 'Content-Type': 'application/json' }
   });


   if (response.data && Object.keys(response.data).length) {
     return { props: { products: response.data } };
   }


   logError('getJbo', new Error('No data returned from API'));
   return { props: { products: [] } };
 } catch (error) {
   logError('getJbo', error);
   return { props: { products: [] } };
 }
}
export default CategoryList;



