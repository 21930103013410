import { useRouter } from 'next/router';
import { useEffect, useState,useContext } from 'react';
import CategoryComponent from "./category/CategoryComponent";
import ShopComponent from './shop/ShopComponent';
import ProductComponent from './product/ProductComponent';
import HomePage from './HomePage';
import CustomPage from './custom/CustomPage';
import getRedisClient from '../utils/redis';
import { RedisContext } from "../contexts/RedisContext";
import errorLogApi from '../components/custom/ErrorLogApi';
import getRedisClientLocal from '../utils/localredis';
import { setCookie, getCookie, deleteCookie } from '../utils/cookie';
import axios from "axios";
import { useAxios } from "../utils/axios";
import {PageNotFound} from './404';
export async function getServerSideProps(context) {
  const { req } = context;
  const referer = req.headers.referer || req.headers.referrer;
  let serverReferrer = referer ?? '/'; //get the Referrer url for the Single product page to get the Previous Url
  // Helper function to extract the domain name
  function sanitizeHostname(host) {
    const hostname = new URL(`https://${host}`).hostname;
    return hostname;
  }
  const logError = (endpoint, error) => {
    const responseData = error.response?.data ?? '';
    errorLogApi({
      job_id: "",
      endpoint: endpoint,
      payload: '',
      responseData: responseData,
      errorMsg: error
    });
  };

  
  const hostname = req.headers.host === "localhost:3000" ? process.env.NEXT_PUBLIC_WEB_HOST : sanitizeHostname(req.headers.host);

  const redishostname = sanitizeHostname(req.headers.host);
  const redis = redishostname.startsWith('localhost') ? getRedisClientLocal() : getRedisClient();
  // const redis = getRedisClient();
  

  const previewUrl = req.headers.host === "localhost:3000"
    ? "https://preview.jpencil.in/?pid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjIzMywic2l0ZV9zdGF0dXMiOjAsImlhdCI6MTcxNDY0MzI4MX0.QXsuG6PL2rBZP92YZMTeMRnO3at8Sfq2gOf22rG6jeg"
    : req.url;

  const urlObj = new URL(previewUrl, `http://${hostname}`);
  const pid = urlObj.searchParams.get('pid');
  
  if (hostname.startsWith('preview.')) {
    const innerJson = JSON.stringify({
      domain: hostname,
      token: pid
    });

    const payload = {
      data: innerJson
    };

    
    try {
      const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      
      return { props: { products: response.data ,serverReferrer} };
    } catch (error) {
      // To capture logs
      logError('getJbo', error);
    }
  }
  try {

    const cachedProducts = await redis.get(hostname);

    if (cachedProducts) {
      console.log("cachedproducts data,",cachedProducts);
      return { props: { products: JSON.parse(cachedProducts),serverReferrer } };
    }
    // If no cache, make an API call for no cached Products
    const innerJson = JSON.stringify({ domain: hostname });
    const payload = {
      data: JSON.stringify({ domain: hostname })
    };

    try {
     
      const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
        headers: { 
          'Content-Type': 'application/json',
         },
         timeout: 30000, 
      });
  
      if (response.data && Object.keys(response.data).length) {
        console.log("get jbo data",response.data);
        return { props: { products: response.data ,serverReferrer} };
      }
    } 
    catch(e){
    
      // To capture logs
      logError('getJbo', e);
      // Handle the scenario where the API call does not return data
      console.error("API call made but no data received 1");
    }
  
    return { props: { products: [],serverReferrer } };

  } catch (error) {
    // To capture logs
   
    
    logError('getJbo', error);
    // Handle the scenario where the API call does not return data
    console.error("API call made but no data received 2");
    return { props: { products: [] ,serverReferrer} };
  }
}

const CommonSlugPage = ({ products,serverReferrer }) => {
    const router = useRouter();
    const { footerDatas } = useContext(RedisContext);
   
  const [pageComponent, setPageComponent] = useState(null);
  const axiosInstance = useAxios();
  let jboId = getCookie("jb_id");
  let productsData = getCookie("productsData");
  const logError = (endpoint, error) => {
    const responseData = error.response?.data ?? '';
    errorLogApi({
      job_id: "",
      endpoint: endpoint,
      payload: '',
      responseData: responseData,
      errorMsg: error
    });
  };
  if (!jboId && !productsData && typeof window !== 'undefined') {
    useEffect(() => {
      const url = window.location.href;
      const parsedUrl = new URL(url);
      const currentHostname = window.location.hostname === "localhost" ? process.env.NEXT_PUBLIC_WEB_HOST : parsedUrl.hostname;
      // Cookies have no products data
      function transformData(inputData) {
        
        return {
          primary: inputData.primary_color,
          secondary: inputData.secondary_color,
          alternative: inputData.alternative_color,
          font: inputData.primary_font,
          secondaryFont: inputData.secondary_font,
          alternativeFont: inputData.alternative_font,
          title: "Default Title",
          content: "Default Content",
          currency: "Default Currency",
          token: inputData.token,
          siteStatus: inputData.site_status,
          splashLogo: inputData.spalsh_logo,
          favicon: inputData.fav_icon
        };
      }
      try {
        const payload = {
          data: JSON.stringify({ domain: currentHostname })
        };
        axios.post(`https://${currentHostname}/api/user/getJbo`, payload).then((res) => {
          setCookie('jb_id', JSON.stringify(res.data.jbo_id), 7);
          const transformedData = transformData(res.data);
          setCookie('productsData', JSON.stringify(transformedData), 7);
        });
      } catch (error) {
        logError('getJbo', error);
      }
    }, []); // Empty dependency array ensures this runs only once
  }

  // Function to transform product data
  function transformData(inputData) {
    return {
      primary: inputData.primary_color,
      secondary: inputData.secondary_color,
      alternative: inputData.alternative_color,
      font: inputData.primary_font,
      secondaryFont: inputData.secondary_font,
      alternativeFont: inputData.alternative_font,
      title: "Default Title",
      content: "Default Content",
      currency: "Default Currency",
      token: inputData.token,
      siteStatus: inputData.site_status,
      splashLogo: inputData.spalsh_logo,
      favicon: inputData.fav_icon
    };
  }

  function transformLiveData(inputData) {
    return {
      primary: inputData.primary_colour,
      secondary: inputData.secondary_colour,
      alternative: inputData.alternative_colour,
      font: inputData.primary_font,
      secondaryFont: inputData.secondary_font,
      alternativeFont: inputData.alternative_font,
      title: "Default Title",
      content: "Default Content",
      currency: "Default Currency",
      token: inputData.token,
      siteStatus: inputData.site_status,
      splashLogo: inputData.spalsh_logo,
      favicon: inputData.fav_icon
    };
  }

  if (products) {
    let liveData = {};
    let previewData = {};
    // Determine hostname to conditionally process products
    const hostname = window.location.hostname === "localhost" ? process.env.NEXT_PUBLIC_WEB_HOST : window.location.hostname;

    // Check if products is an array
    if (Array.isArray(products)) {
      products.forEach(item => {
        if (item.site_status === 0) {
          previewData = item;
        } else if (item.site_status === 1) {
          liveData = item;
        }
      });
      setCookie('jb_id', JSON.stringify(liveData.jbo_id), 7);
      const transformedData = transformLiveData(liveData);
      setCookie('productsData', JSON.stringify(transformedData), 7);
    } else {
      
      setCookie('jb_id', JSON.stringify(products.jbo_id), 7);
      const transformedData = transformData(products);
      
      setCookie('productsData', JSON.stringify(transformedData), 7);
    }
    productsData = getCookie("productsData");
    jboId = getCookie("jb_id");
  }

  // cid declare for footer modifications - 13-08-2024
  const { slug, cid } = router.query;

  useEffect(() => {
    const { m } = router.query;
   // Get the current URL
const currentUrl = window.location.href;

 
   // Check for slug in footer custom pages
   const footerCustomPageMatch = footerDatas?.footer_menu?.some((menu) =>
    menu.custom_page?.some(
      (page) =>
        page.custom_page_title.replace(/\s+/g, '-').toLowerCase() === slug[0]?.toLowerCase()
    )
  );

  if (footerCustomPageMatch) {
   
    // Redirect to custom/[...slug].js if slug matches a custom page
    setPageComponent(<CustomPage />);
    return;
  }
    // Check for specific query parameters
if (slug.includes('buy')) {
  setPageComponent(<ProductComponent serverReferrer={serverReferrer}/>);
} else if (slug.length === 3) {
  setPageComponent(<ShopComponent />);
} else if (m === 'thumbnail') {
  setPageComponent(<CategoryComponent />);
}  
 else if (slug.length === 1) {
  setPageComponent(<PageNotFound />);
} else if (slug[0] === 'user' && slug[1] === 'viewproduct' && slug[2]) {
  const tagLabel = slug[2];
  axios.post('/menu/viewProductDetailUrl', { tagLabel })
    .then(response => {
      if (response.data.status === 1) {
        router.push(response.data.url);
      }
    })
    .catch(error => {
      console.error('viewProductDetailUrl error', error);
    });
} else {
  setPageComponent(<PageNotFound />);
}

     // Check for QR api 
     if (slug[0] === 'user' && slug[1] === 'viewproduct' && slug[2]) {
      const tagLabel = slug[2];
      axiosInstance.post('menu/viewProductDetailUrl', { 'tagLabel': tagLabel })
        .then(response => {
          if (response.data.status === 1) {
            router.push(response.data.url);;
          }
        })
        .catch(error => {
          logError('viewProductDetailUrl', error);
        });
    }
  }, [router.query]);

  // Render a placeholder or loading component while determining the correct component to render
  if (!pageComponent) {
    return <div></div>;
  }

  return pageComponent;
};

export default CommonSlugPage;
