import { useState } from 'react';
import { FaTimes } from 'react-icons/fa'; // Importing the close icon from React Icons

const PhoneNumberModal = ({ isOpen, onClose, onSubmit }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(''); // State to store validation error

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const phoneRegex = /^[6-9]\d{0,9}$/; // Valid Indian phone number pattern (starts with 6-9, up to 10 digits)

    if (value === '' || phoneRegex.test(value)) {
      setPhoneNumber(value); // Update value if it matches the pattern or is empty
      setError(''); // Clear error if the value is valid
    } else {
      setError('Invalid phone number. It must start with 6-9 and be 10 digits long.');
    }
  };

  const handleSubmit = () => {
    if (phoneNumber.length !== 10) {
      setError('Phone number must be exactly 10 digits.');
      return;
    }
    onSubmit(phoneNumber);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="relative bg-white rounded-lg p-8 w-11/12 max-w-md shadow-lg transform transition-all">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
        >
          <FaTimes size={20} />
        </button>

        {/* Modal Header */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Enter Your Phone Number</h2>

        {/* Input Field */}
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={handlePhoneChange}
          className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
          style={{
            outline: 'none',
            boxShadow: 'none',
          }}
        />
        {error && (
          <p className="mt-2 text-sm text-red-500">{error}</p> // Display validation error
        )}

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-primary rounded-lg transition"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberModal;
