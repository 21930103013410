import { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { useGoogleLogin } from '@react-oauth/google';
import { UserContext } from '../../contexts/UserContext';
import { useAxios } from '../../utils/axios';
import PhoneNumberModal from './PhoneNumberModel'; // Ensure correct import

export const useGoogleAuth = () => {
  const axiosInstance = useAxios();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Ensure state is defined
  const [tempUserId, setTempUserId] = useState(null);
  const router = useRouter();
  const { setUserId, setToken, setUserDetails, setPrivilegeStatus, setError } = useContext(UserContext);

  const onSuccess = async (response) => {
    setLoading(true);
    try {
      const { access_token } = response;

      const userInfoResponse = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`);
      const userInfo = await userInfoResponse.json();

      const payload = {
        id: userInfo.id,
        email: userInfo.email,
        verified_email: userInfo.verified_email,
        name: userInfo.name,
        given_name: userInfo.given_name,
        family_name: userInfo.family_name,
        picture: userInfo.picture,
        hd: userInfo.hd || "",
        token: access_token,
      };

      const apiResponse = await axiosInstance.post('login/GoogleAuthLogin', payload);

      if (apiResponse.data.status === 1) {
        const { userId, name, email, phone_no, privilege_card } = apiResponse.data.data;

        localStorage.setItem('userId', userId);
        localStorage.setItem('token', access_token);
        localStorage.setItem('userDetails', JSON.stringify({ name, email, phone_no, userId }));
        localStorage.setItem('privilegeStatus', privilege_card);

        setUserId(userId);
        setUserDetails({ name, email, phone_no, userId });
        setToken(access_token);
        setPrivilegeStatus(privilege_card || 0);

        if (!phone_no || phone_no == '') {
          setTempUserId(userId);
          setIsModalOpen(true); // Open modal if phone_no is missing
        } else {
          window.location.href='/';
        }
      } else {
        setError('Failed to authenticate with GoogleAuthLogin');
      }
    } catch (error) {
      setError('Error during Google Login process');
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneNumberSubmit = async (phoneNumber) => {
    try {
      const payload = {
        user_id: tempUserId,
        phone_no: phoneNumber,
      };
  
      // Call the API to update the phone number
      const response = await axiosInstance.post('login/updatePhoneNumber', payload);
  
      // Check the response status
      if (response.data.status == 1) {
        console.log(response.data.msg); // Log success message
       
        setIsModalOpen(false);
          // Extract the updated phone number from the response
      const updatedPhoneNo = response.data.phone_no;
           // Extract the updated phone number from the response
      // Update user details with the new phone number
      setUserDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          phone_no: updatedPhoneNo,
        };

        // Update localStorage with the new details
        localStorage.setItem('userDetails', JSON.stringify(updatedDetails));

        return updatedDetails; // Return the updated details for state
      });
      window.location.href='/';
     
      } else {
        console.error('Failed to update phone number:', response.data.msg);
        // Optionally, show the error message to the user
       
      }
    } catch (error) {
      console.error('Error updating phone number:', error);
      // Optionally, show a generic error message to the user
     
    }
  };
  
  return {
    login: useGoogleLogin({
      onSuccess,
      onError: (error) => setError('Google Login Failed'),
      flow: 'implicit',
    }),
    loading,
    PhoneNumberModal: () => (
      <PhoneNumberModal
        isOpen={isModalOpen} // Use the state here
        onClose={() => setIsModalOpen(false)}
        onSubmit={handlePhoneNumberSubmit}
      />
    ),
  };
};
